import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Гражданские споры | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ К выбору своего адвоката следует относиться ответственно. Специалисты нашей компании – настоящие профессионалы, которые зарекомендовали себя с лучшей стороны." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/grazhdanskie-spory'
				name="Гражданские споры | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ К выбору своего адвоката следует относиться ответственно. Специалисты нашей компании – настоящие профессионалы, которые зарекомендовали себя с лучшей стороны."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Гражданские споры" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Гражданские споры"}
			
				html={

				`
				<p class="TextText">
					<b>Услуги адвоката по гражданским делам в Казани</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Некоторые наши соотечественники по-прежнему с недоверием относятся к суду, как к реальному защитнику их прав. Часто это связанно с тем, что граждане плохо ориентируются на правовом «поле» и просто не знают, как защитить собственные интересы.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Незнание процессуальных норм не должно стать преградой для реализации своих законных прав. Именно для решения данной проблемы работают адвокаты. Эти специалисты в области права профессионально занимаются представительством в суде: они будут защищать права и интересы своего клиента.
				</p>

				<p class="TextText">
					<b>Помощь адвоката – комплексная услуга, в состав которой включаются:</b>
				</p>

				<p class="TextText">
					правовые консультации (письменные и устные), связанные с делом клиента
				</p>

				<p class="TextText">
					сбор доказательств по делу, составление процессуальных документов
				</p>

				<p class="TextText">
					представительство в ходе претензионного порядка урегулирования спора и в суде
				</p>

				<p class="TextText">
					обжалование решения в высших судебных инстанциях
				</p>

				<p class="TextText">
					представительство в ходе исполнительного производства
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Прежде всего с клиентом проводится консультация, в ходе которой юрист знакомится с делом и дает прогноз целесообразности вступления в судебный спор. Затем начинается подготовительный этап: формулирование искового заявления и сбор доказательств. Напоминаем, что, согласно действующему процессуальному законодательству, каждая сторона самостоятельно представляет доказательства для обоснования своих требований. В связи с этим сбор сведений, доподлинно подтверждающих те или иные факты, имеет немаловажное значение.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Кроме того, в некоторых случаях закон обязывает стороны соблюсти досудебный порядок урегулирования разногласий. Зачастую он позволяет избавиться от необходимости обращаться непосредственно в суд. Адвокат грамотно составит претензию и проведет переговоры с противоположной стороной спора.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					В том случае, когда досудебный порядок либо вовсе не проводился, либо не дал никаких результатов, следует обратиться в суд. Для возбуждения дела потребуется исковое заявление. Ответчик в свою очередь подает отзыв на него. Специалисты отмечают, что грамотное составление данных процессуальных документов – это уже 50% успеха. Адвокат поможет клиентам оформить иск и представит его в суд, действуя на основе выданной доверенности.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Он представит доказательства, выступит с речью. Плюс в случае необходимости, специалист обжалует судебный вердикт в высших инстанциях.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Кроме того, реализация даже положительного для клиента решения может потребовать присутствия профессионала, который также будет действовать от его имени в ходе исполнительного производства.
				</p>

				<p class="TextText">
					<b>Участие адвоката в гражданском деле: преимущества</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Практика показывает, что привлечение специалиста может изменить исход самого безнадежного дела. Адвокат поможет собрать доказательства, выгодно представить их в ходе судебного заседания, оспорить неправомерные действия других участников процесса. Плюс в том, что привлечение правового помощника – это гарант того, что любое действие клиента будет законным.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Но к выбору своего адвоката следует относиться ответственно. Специалисты нашей компании – настоящие профессионалы, которые зарекомендовали себя с лучшей стороны. Во многом этому способствует опыт представительства в государственных органах и судах различных инстанций.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
